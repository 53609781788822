
module.exports = {
  firebase:{
    apiKey: "AIzaSyBdVIZc2eaMeB1ZVXdAXNuSry32iv_BYks",
    authDomain: "akron-dashboard.firebaseapp.com",
    databaseURL: "https://akron-dashboard-default-rtdb.firebaseio.com",
    projectId: "akron-dashboard",
    storageBucket: "akron-dashboard.appspot.com",
    messagingSenderId: "89871853049",
    appId: "1:89871853049:web:4f1dc7be4186adb2f34928",
    measurementId: "G-LCJMWTF4RW"
  },
  url:{
    clerkOffice: 'https://cms.akron.arsconnect.com/uploads/lobby_informationdesk_5f44399b7b.png'
  }
}

