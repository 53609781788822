import {db} from "@/firebase"
export default {
    state: {
        contentFetched: false,
        appDimensions: {},
        pageHeader: null,
        pdfViewer: {},
        imageViewer: {},
        emailViewer: {},
        optionsViewer: {},
        courtTimingViewer: {},

        // user session handling (Displayed on Dashboard App)
        startUserSession: undefined,
        endUserSession: undefined,
        userQnaRequest: [],
        touchRequest: [],
        userSession: {},
        sessionEndViewer: false
    },
    getters: {
        contentFetched: state => state.contentFetched,
        appDimensions: state => state.appDimensions,
        getPageHeader: state => state.pageHeader,
        getPdfViewer: state => state.pdfViewer,
        getImageViewer: state => state.imageViewer,
        getEmailViewer: state => state.emailViewer,
        getOptionsViewer: state => state.optionsViewer,
        getCourtTimingViewer: state => state.courtTimingViewer,
        getStartUserSession: state => state.startUserSession,
        getEndUserSession: state => state.endUserSession,
        getTouchRequest: state => state.touchRequest,
        getUserQnaRequest: state => state.userQnaRequest,
        getSessionEndViewer: state => state.sessionEndViewer
    },
    mutations: {
        // setAppDimensions(state) {
        //     let newWidht = window.innerWidth;
        //     let newHeight = (1080 / 1920) * window.innerWidth;
        //     //proto: (original height / original width) x new width = new height
        //     // console.log(`New App Dimensions: (w X h) : (${newWidht} X ${newHeight})`);
        //     state.appDimensions = {
        //         width: newWidht,
        //         height: newHeight
        //     }
        // },
        setAppDimensionDynamic(state, obj){
            state.appDimensions = obj
        },
        setPageHeader(state, value) {
            state.pageHeader = value;
        },
        setPdfViewer(state, pdfObj) {
            state.pdfViewer = pdfObj;
        },
        setImageViewer(state, imgObj) {
            state.imageViewer = imgObj
        },
        closeImageViewer(state) {
            state.imageViewer = {show: false}
        },
        setEmailViewer(state, emailObj) {
            state.emailViewer = emailObj
        },
        closeEmailViewer(state) {
            state.emailViewer = {show: false}
        },
        setOptionsViewer(state, optionsObj) {
            state.optionsViewer = optionsObj
        },
        closeOptionsViewer(state) {
            state.optionsViewer = {show: false}
        },
        setCourtTimingViewer(state, courtObj) {
            state.courtTimingViewer = courtObj
        },
        closeCourtTimingViewer(state) {
            state.courtTimingViewer = {show: false}
        },
        setStartUserSession(state, startUserSession) {
            state.startUserSession = startUserSession
        },
        setEndUserSession(state, endUserSession) {
            state.endUserSession = endUserSession
        },
        setTouchRequest(state, touchRequest) {
            state.touchRequest.push(touchRequest)
        },
        setUserQnaRequest(state, userQnaRequest) {
            state.userQnaRequest.push(userQnaRequest)
        },
        setSessionEndViewer(state, status){
            state.sessionEndViewer = status
          }
    },
    actions: {
        openImageViewer({commit}, imgObj) {
            imgObj.show = true
            commit('setImageViewer', imgObj)
        },
        openEmailViewer({commit}, emailObj) {
            emailObj.show = true
            commit('setEmailViewer', emailObj)
        },
        openOptionsViewer({commit}, optionsObj) {
            optionsObj.show = true
            commit('setOptionsViewer', optionsObj)
        },
        openCourtTimingViewer({commit}, courtObj) {
            courtObj.show = true
            commit('setCourtTimingViewer', courtObj)
        },
        updateUserSession({getters, state}) {
            db.collection('userSession').add({
                kioskID: getters.getKioskId,
                userSessionStartTime: getters.getStartUserSession,
                userSessionEndTime: getters.getEndUserSession,
                userSessionLogs: JSON.stringify(getters.getTouchRequest),
                //userQnaRequest: JSON.stringify(getters.getUserQnaRequest)
            }).then(response => {
                console.log('User Handling Data saved to firebase' + new Date())
                state.touchRequest = []
                state.userQnaRequest = []
            })
        }
    }
}
