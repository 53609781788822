<template>
  <v-row no-gutters class="fill-height mt-5">
    <v-col cols="12" align="center">
      <v-card height="560" class="containerBorder" flat width="85%">
        <v-row no-gutters class="mt-5">
          <v-col
            cols="4"
            v-for="(form, index) in $t('trafficForms')"
            :key="index"
          >
            <v-card
              width="80%"
              height="90"
              class="ma-3 PDFborder"
              @click="showForms(form)"
            >
              <v-row no-gutters class="fill-height">
                <v-col cols="2" align-self="center">
                  <v-icon size="60" color="red darken-4"
                    >mdi-file-pdf-box</v-icon
                  >
                </v-col>
                <v-col cols="10" align="start" align-self="center">
                  <span class="headline pl-5">
                    {{ form.formName }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "trafficForms",
  components: {},
  computed: {
    ...mapGetters(["appDimensions"]),
  },
  methods: {
    showForms(form) {
      console.log(form.formPdf[0].url);
      this.$store.commit("setPageHeader", form.formName + " Form");
      this.$store.commit("setPdfViewer", {
        url: form.formPdf[0].url,
        courtType: "traffic",
      });
      this.$store.dispatch("avatarSpeak", form.speech);
      this.$router.push("/displayForms");
    },
  },
};
</script>
<style>
.containerBorder {
  border-width: 3px;
  border-style: solid;
  border-color: #3357c0 !important;
  border-radius: 8px !important;
  overflow-y: scroll;
}

.PDFborder {
  border-style: solid;
  border-width: 2px;
  border-color: #3357c0 !important;
  overflow-y: hidden;
  border-radius: 15px !important;
}
</style>
