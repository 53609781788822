<template>
  <v-row justify="center" align="start" no-gutters>
    <v-col class="pt-8" cols="12" align="center">
      <span class="text-h2 font-weight-bold grey--text text--darken-3">
        {{ $t("default") }}
      </span>
    </v-col>
    <v-col cols="9">
      <v-row no-gutters class="fill-height">
        <v-col
          align="center"
          cols="6"
          v-for="page in $t('homepages')"
          :key="page.displayPosition"
        >
          <v-card
            @click="goto(page)"
            class="menuItem d-flex flex-column align-center justify-center my-6"
          >
            <v-img :src="page.menuIcon.url" width="60%" contain> </v-img>
            <v-card-text class="text-h4 primary--text font-weight-bold">
              {{ page.menuName }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" align="center">
      <v-divider class="mx-10 my-5"></v-divider>
      <span class="text-h2 font-weight-bold grey--text text--darken-3 pt-10">Municipal Court of Akron, Ohio</span>
      <!-- <languageBar></languageBar> -->
    </v-col>
  </v-row>
</template>

<script>
import languageBar from "@/components/languageBar";
import { mapGetters } from "vuex";

export default {
  name: "menuMatrix",
  components: {
    languageBar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getCMSlink", "getTouchCount", "isUserLooking"]),
  },
  methods: {
    goto(page) {
      if (page.pageType.length !== 0) {
        switch (page.pageType[0].__typename) {
          case "ComponentHomepageNavigation":
            this.$router.push("/" + page.pageType[0].pathName.toLowerCase());
            this.$store.commit("setPageHeader", page.pageType[0].pageHeader);
            this.$store.commit("setQnaKeyword", "");

            // To start session using touch - when no face detected
            if (!this.isUserLooking) {
              this.$store.commit("setTouchCount", this.getTouchCount + 1);
              if (this.getTouchCount === 1) {
                // start time
                this.$store.commit("setStartUserSession", new Date());

                //session start object
                this.$store.commit("setTouchRequest", {
                  module: "Default",
                  action: "Touch Detected",
                  response: "User Session Started - Touch Detected",
                  timeStamp: new Date(),
                  requestType: "Session",
                });
              }
            }
            // Will be called irrespective of Touch Count
            this.$store.commit("setTouchRequest", {
              module: "Home Page",
              action: "navigate",
              response: page.pageType[0].pathName,
              timeStamp: new Date(),
              requestType: "Touch",
            });
            break;
          case "ComponentHomepageDialogBox":
            console.log(page.pageType[0].popup)
            this.$store.dispatch("openOptionsViewer", {
              dialogHeader: "Please tap the option you prefer",
              dialogData: page.pageType[0].popup,
            });
            break;
          default:
            break;
        }
      }
      this.$store.commit("setDefaultBubbleText", true);
      this.$store.dispatch("avatarSpeak", page.speech);
    },
  },
};
</script>

<style>
.menuItem {
  border: thick solid #3357c0 !important;
  width: 300px;
  height: 280px;
}
</style>

<i18n>
{
  "en": {
      "default": "How Can I Help You?"
    },
  "es":{
      "default": "¿Le puedo ayudar en algo?"
    }
}
</i18n>
