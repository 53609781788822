let request_as_promised = require('request-promise');
let config = require('./../../../config/config')
import router from "@/router";

export default {
    state: {
        qnaStatus: null,
        qnaKeyword: '',
        wrongAnswerCount: 0,
        court: ''
    },
    getters: {
        getQnaStatus: state => state.qnaStatus,
        getQnaKeyword: state => state.qnaKeyword,
        getWrongAnsCount: state => state.wrongAnswerCount,
        getCourt: state => state.court
    },
    mutations: {
        setQnaStatus(state, status) {
            state.qnaStatus = status;
        },
        setQnaKeyword(state, keyword) {
            state.qnaKeyword = keyword
        },
        setWrongAnswerCount(state, count) {
            state.wrongAnswerCount = count
        },
        setCourt(state, court) {
            state.court = court
        }
    },
    actions: {
        requestQnA({ getters, dispatch, commit }, transcript) {
            return new Promise((response, reject) => {
                
                commit('setQnaStatus', 'Thinking')

                let question = {
                    'question': transcript,
                    'top': 3,
                    'scoreThreshold': 10,
                    'rankerType': 'QuestionOnly',
                    'strictFilters': [
                        { "name": "location", "value": "common" },
                        { "name": "location", "value": "lobby" }
                    ],
                    "strictFiltersCompoundOperationType": "OR",
                    "activeLearningEnabled": true,
                };
                if (typeof transcript === 'object') {
                    question = {
                        'question': transcript.displayText,
                        'top': 3,
                        'scoreThreshold': 10,
                        'rankerType': 'QuestionOnly',
                        'qnaId': transcript.qnaId
                    };
                    
                }
                let options = {
                    uri: getters.getAzureHost + getters.getAzureRoute,
                    method: 'POST',
                    headers: {
                        'Authorization': "EndpointKey " + getters.getAzureEndpointKey
                    },
                    json: true,
                    body: question
                };

                request_as_promised.post(options)
                    .then(result => {
                        const qnaAnalytics = {
                            timeStamp: new Date(),
                            kioskID: getters.getKioskId,
                            transcript: transcript,
                            result: result,
                        }
                        commit('setTouchRequest', {
                            module: 'QnA',
                            action: transcript,
                            response: result.answers[0].answer,
                            timeStamp: new Date(),
                            requestType: 'Speech'
                        })
                        dispatch('sendQnaData', qnaAnalytics);
                        if (result.answers[0].score > 60) {
                            dispatch('generateAnswer', result)
                        } else if (result.answers[0].score > 45) {
                            dispatch('avatarSpeak', 'I didn\'t quite get that, did you mean to ask following questions. Please tap your preference.')
                            let followUp = [];
                            result.answers.forEach(output => {
                                if (output.score > 45)
                                    followUp.push({ name: output.questions[0] })
                            });
                            dispatch('openOptionsViewer', {
                                dialogHeader: 'Please tap the option you prefer',
                                dialogData: followUp,
                            })
                        } else if (result.answers[0].id === -1) {
                            dispatch('openImageViewer', {
                                url: config.url.clerkOffice
                            })
                            dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Information Desk located here.')
                        } else {
                            if (getters.getWrongAnsCount === 0) {
                                commit('setWrongAnswerCount', getters.getWrongAnsCount + 1);
                                dispatch('avatarSpeak', 'Sorry! I just started working here, I will soon know answers to all the court related questions. Please try asking different question or visit Information Desk.')
                            } else if (getters.getWrongAnsCount === 1) {
                                commit('setWrongAnswerCount', getters.getWrongAnsCount + 1);
                                dispatch('avatarSpeak', 'Sorry! I don\'t know the answer to this. I will make sure it will be there next time you ask. For now, please try asking different question.')
                            } else if (getters.getWrongAnsCount === 2) {
                                commit('setWrongAnswerCount', 0);
                                dispatch('openImageViewer', {
                                    url: config.url.clerkOffice
                                })
                                dispatch('avatarSpeak', 'Sorry I’m still learning. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Information Desk located here.')
                            }
                        }

                    })
            })
        },
        generateAnswer({ dispatch, commit, getters }, result) {
            commit('setQnaStatus', false)
            const metadata = result.answers[0].metadata.find(({ name }) => name === 'intent').value
            const ansObj = result.answers[0]
            commit('setDefaultBubbleText', false)
            commit('closeOptionsViewer')
            switch (metadata) {
                case "navigate":
                    var navPath = getters.getHomepageEnglish.find(({ menuName }) => menuName.toLowerCase() === ansObj.metadata.find(({ name }) => name === 'navigateto').value)
                    if (router.currentRoute.path !== navPath.pageType[0].pathName.toLowerCase()) {
                        router.push('/' + navPath.pageType[0].pathName).then(() => {
                            commit('setPageHeader', navPath.pageType[0].pageHeader)
                            dispatch('avatarSpeak', navPath.speech)
                        })
                    } else {
                        commit('setQnaKeyword', '')
                        dispatch('avatarSpeak', navPath.speech)
                    }
                    break;

                case "showmap":
                    try {
                        var mapPath = getters.getMapsEnglish.get(ansObj.metadata.find(({ name }) => name === 'mappath').value)
                        dispatch('openImageViewer', {
                            name: mapPath.mapName,
                            url: mapPath.mapImage
                        })
                        dispatch('avatarSpeak', ansObj.answer);
                    } catch (e) {
                        dispatch('openImageViewer', {
                            url: config.url.clerkOffice
                        })
                        dispatch('avatarSpeak', 'Sorry, I can’t find the requested location, please go to the Information Desk for more information.')
                    }

                    break;

                case "showimage":
                    try {
                        var imagePath = getters.getPopupsEnglish.get(ansObj.metadata.find(({ name }) => name === 'mappath').value)
                        dispatch('openImageViewer', {
                            name: imagePath.popupName,
                            url: imagePath.popupImage
                        })
                        dispatch('avatarSpeak', ansObj.answer);
                    } catch (e) {
                        dispatch('openImageViewer', {
                            url: config.url.clerkOffice
                        })
                        dispatch('avatarSpeak', 'Sorry, I can’t find the requested location, please go to the Information Desk for more information.')
                    }

                    break;

                case "speak":

                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showformtraffic":
                    try {
                        var name = getters.getTrafficFormsEnglish.find(({ formName }) => formName.toLowerCase() === ansObj.metadata.find(({ name }) => name === 'formname').value)
                        if (router.currentRoute.path !== "/displayForms") {
                            router.push("/displayForms")
                        }
                        commit("setPageHeader", name.formName + " Form");
                        commit("setPdfViewer", {
                            url: name.formPdf[0].url,
                            courtType: "traffic",
                        });
                        dispatch('avatarSpeak', ansObj.answer);
                    } catch (e) {
                        dispatch('openImageViewer', {
                            url: config.url.clerkOffice
                        })
                        dispatch('avatarSpeak', 'Sorry! I just started working here, I will soon know answers to all the court related questions. Pleas try asking different question or visit Information Desk.');
                    }
                    break;

                case "showformeviction":
                    try {
                        var ename = getters.getEvictionFormsEnglish.find(({ formName }) => formName.toLowerCase() === ansObj.metadata.find(({ name }) => name === 'formname').value)
                        if (router.currentRoute.path !== "/displayForms") {
                            router.push("/displayForms")
                        }
                        commit("setPageHeader", ename.formName + " Form");
                        commit("setPdfViewer", {
                            url: ename.formPdf[0].url,
                            courtType: "eviction",
                        });
                        dispatch('avatarSpeak', ansObj.answer);
                    } catch (e) {
                        console.log(e.message)
                        dispatch('openImageViewer', {
                            url: config.url.clerkOffice
                        })
                        dispatch('avatarSpeak', 'Sorry! I just started working here, I will soon know answers to all the court related questions. Pleas try asking different question or visit Information Desk.');
                    }
                    break;
                case "followup":

                    var prompts = (ansObj.context).prompts
                    var followUp = [];
                    dispatch('avatarSpeak', ansObj.answer);
                    prompts.forEach(item => {
                        followUp.push({ name: item.displayText })
                    })

                    dispatch('openOptionsViewer', {
                        dialogHeader: 'Tap on the option below, or click on the microphone to ask another question.',
                        dialogData: followUp,
                    })
                    break;
                default:
                    dispatch('openImageViewer', {
                        url: config.url.clerkOffice
                    })
                    dispatch('avatarSpeak', 'Sorry I’m still learning. Please ask me a different question or, get information about this topic at the Information Desk located here.')
                    break;
            }
        }

    }
}
