import {
    floorSpanishQuery,
    mapSpanishQuery,
    faqSpanishQuery,
    faqCategorySpanishQuery,
    homepageSpanishQuery,
    popupSpanishQuery
} from '@/graphql/querySpanish';

function today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return (mm + '-' + dd + '-' + yyyy);
}

export default {
    state: {
        homepageSpanish: [],
        floorsSpanish: [],
        mapsSpanish: new Map(),
        formsSpanish: new Map(),
        faqsSpanish: [],
        faqCategorySpanish: [],
        srlGuideSpanish: new Map(),
        formCategoriesSpanish: [],
        formTypesSpanish: [],
        formPacketsSpanish: new Map(),
        courtTypeSpanish: new Map(),
        popupsSpanish: [],

    },
    getters: {
        getHomepageSpanish: state => state.homepageSpanish,
        getFloorsSpanish: state => state.floorsSpanish,
        getMapsSpanish: state => state.mapsSpanish,
        getFaqsSpanish: state => state.faqsSpanish,
        getFaqCategoriesSpanish: state => state.faqCategorySpanish,
        getSrlGuideSpanish: state => state.srlGuideSpanish,
        getFormsSpanish: state => state.formsSpanish,
        getFormCategorySpanish: state => state.formCategoriesSpanish,
        getFormTypeSpanish: state => state.formTypesSpanish,
        getFormPacketsSpanish: state => state.formPacketsSpanish,
        getCourtTypeSpanish: state => state.courtTypeSpanish,
        getPopupsSpanish: state => state.popupsSpanish
    },
    mutations: {
        setHomepageSpanish(state, page) {
            state.homepageSpanish.push(page)
        },
        setFloorsSpanish(state, floor) {
            state.floorsSpanish.push(floor)
        },
        setMapsSpanish(state, map) {
            state.mapsSpanish.set(map.mapName.toLowerCase(), map)
        },
        setFormsSpanish(state, form) {
            state.formsSpanish.set(form.id, form)
        },
        setFaqsSpanish(state, faqs) {
            faqs.forEach(faq => {
                let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, '<br />'),
                    category: faq.category
                }
                state.faqsSpanish.push(data)
            })
            //state.faqs = faqs
        },
        setFaqCategorySpanish(state, faqCategories) {
            state.faqCategorySpanish = faqCategories
        },
        setSrlGuideSpanish(state, guide) {
            state.srlGuideSpanish.set(guide.guideName.toLowerCase(), {
                guideName: guide.guideName,
                category: guide.category,
                srlFile: guide.srlFile.url,
                guideUrl: guide.srlGuideLink
            })
        },
        setFormCategoriesSpanish(state, categories) {
            state.formCategoriesSpanish = categories
        },
        setFormTypesSpanish(state, types) {
            state.formTypesSpanish = types
        },
        setFormPacketsSpanish(state, packet) {
            state.formPacketsSpanish.set(packet.packetName.toLowerCase(), packet)
        },
        setCourtTypesSpanish(state, formCourtType) {
            state.courtTypeSpanish.set(formCourtType.courtType, formCourtType.category)
        },
        setPopupsSpanish(state, popup) {
            state.popupsSpanish = popup
        }
    },
    actions: {
        fetchHomePageSpanish({ commit, dispatch, getters }) {

            getters.getApolloClient.query({
                query: homepageSpanishQuery
            }).then(items => {
                items.data.homepages.forEach(page => {
                    dispatch('saveInCache', page.menuIcon.url)
                        .then(response => {
                            page.menuIcon.url = response
                            commit('setHomepageSpanish', page);
                        })
                })


            })
        },
        // Fetch Floor from strapi
        fetchFloorsSpanish({ commit, dispatch, getters }) {
            getters.getApolloClient.query({
                query: floorSpanishQuery
            }).then(floors => {
                floors.data.floors.forEach(floor => {
                    dispatch('saveInCache', floor.floorImage.url)
                        .then(response => {
                            floor.floorImage.url = response;
                            commit('setFloorsSpanish', floor)
                        })
                })

            })
        },

        // Fetching Maps from Strapi
        fetchMapsSpanish({ commit, getters, dispatch }) {
            getters.getApolloClient.query({
                query: mapSpanishQuery
            }).then(maps => {
                maps.data.maps.forEach(map => {
                    dispatch('saveInCache', map.mapImage.url)
                        .then(response => {
                            commit('setMapsSpanish', {
                                mapName: map.mapName,
                                mapFloor: map.mapFloor,
                                mapImage: response,
                                speech: map.speech
                            })
                        })
                })

            })
        },

        // Fetch Forms from strapi


        fetchFaqsSpanish({ commit, getters }) {
            getters.getApolloClient.query({
                query: faqSpanishQuery
            }).then(faqs => {
                commit('setFaqsSpanish', faqs.data.faqs);

            })
        },

        fetchFaqCategorySpanish({ commit, getters }) {

            getters.getApolloClient.query({
                query: faqCategorySpanishQuery
            }).then(faqCategory => {
                commit('setFaqCategorySpanish', faqCategory.data.faqCategories)

            })
        },

        fetchPopupsSpanish({ commit, getters }) {
            getters.getApolloClient.query({
                query: popupSpanishQuery
            }).then(popup => {
                commit('setPopupsSpanish', popup.data.popups)

            })
        }
    }
}
