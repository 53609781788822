import {
    floorEnglishQuery,
    mapEnglishQuery,
    faqEnglishQuery,
    faqCategoryEnglishQuery,
    homepageEnglishQuery,
    trafficFormQuery,
    evictionFormQuery,
    popupEnglishQuery
} from '@/graphql/query';

function today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return (mm + '-' + dd + '-' + yyyy);
}

export default {
    state: {
        homepageEnglish: [],
        floorsEnglish: [],
        mapsEnglish: new Map(),
        faqsEnglish: [],
        faqCategoryEnglish: [],
        trafficFormsEnglish: [],
        evictionFormsEnglish: [],
        popupsEnglish: new Map()
    },
    getters: {
        getHomepageEnglish: state => state.homepageEnglish,
        getFloorsEnglish: state => state.floorsEnglish,
        getMapsEnglish: state => state.mapsEnglish,
        getFaqsEnglish: state => state.faqsEnglish,
        getFaqCategoriesEnglish: state => state.faqCategoryEnglish,
        getSrlGuideEnglish: state => state.srlGuideEnglish,
        getFormsEnglish: state => state.formsEnglish,
        getFormCategoryEnglish: state => state.formCategoriesEnglish,
        getFormTypeEnglish: state => state.formTypesEnglish,
        getFormPacketsEnglish: state => state.formPacketsEnglish,
        getCourtTypeEnglish: state => state.courtTypeEnglish,
        getTrafficFormsEnglish: state => state.trafficFormsEnglish,
        getEvictionFormsEnglish: state => state.evictionFormsEnglish,
        getPopupsEnglish: state => state.popupsEnglish
    },
    mutations: {
        setHomepageEnglish(state, page) {
            state.homepageEnglish.push(page)
        },
        setFloorsEnglish(state, floor) {
            state.floorsEnglish.push(floor)
        },
        setMapsEnglish(state, map) {
            state.mapsEnglish.set(map.mapName.toLowerCase(), map)
        },
        setFaqsEnglish(state, faqs) {
            faqs.forEach(faq => {
                let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, '<br />'),
                    category: faq.category
                }
                state.faqsEnglish.push(data)
            })
        },
        setFaqCategoryEnglish(state, faqCategories) {
            state.faqCategoryEnglish = faqCategories
        },
        setTrafficFormsEnglish(state, trafficForm) {
            state.trafficFormsEnglish.push(trafficForm)
        },
        setEvictionFormsEnglish(state, evictionForm) {
            state.evictionFormsEnglish.push(evictionForm)
        },
        setPopupsEnglish(state, popup) {
            state.popupsEnglish.set(popup.popupName.toLowerCase(), popup)
        }
    },
    actions: {
        fetchHomePageEnglish({ commit, dispatch, getters }) {
            getters.getApolloClient.query({
                query: homepageEnglishQuery
            }).then(items => {
                items.data.lobbyHomepages.forEach(page => {
                    dispatch('saveInCache', page.menuIcon.url)
                        .then(response => {
                            page.menuIcon.url = response
                            if (page.pageType[0].__typename === 'ComponentHomepageNavigation') {
                                commit('setHomepageEnglish', page);
                            } else {
                                page.pageType[0].popup.forEach(popup => {
                                    dispatch('saveInCache', popup.image.url)
                                        .then(response => {
                                            popup.url = response
                                        })
                                })
                                commit('setHomepageEnglish', page);
                            }
                        })
                })

            })
        },
        // Fetch Floor from strapi
        fetchFloorsEnglish({ commit, dispatch, getters }) {
            getters.getApolloClient.query({
                query: floorEnglishQuery
            }).then(floors => {
                floors.data.lobbyFloors.forEach(floor => {
                    dispatch('saveInCache', floor.floorImage.url)
                        .then(response => {
                            floor.floorImage.url = response;
                            commit('setFloorsEnglish', floor)
                        })
                })

            })
        },

        // Fetching Maps from Strapi
        fetchMapsEnglish({ commit, getters, dispatch }) {

            getters.getApolloClient.query({
                query: mapEnglishQuery
            }).then(maps => {
                maps.data.lobbyMaps.forEach(map => {
                    dispatch('saveInCache', map.mapImage.url)
                        .then(response => {
                            commit('setMapsEnglish', {
                                mapName: map.mapName,
                                mapFloor: map.mapFloor,
                                mapImage: response,
                                speech: map.speech
                            })
                        })
                })
            })
        },
        fetchFaqsEnglish({ commit, getters }) {

            getters.getApolloClient.query({
                query: faqEnglishQuery
            }).then(faqs => {
                commit('setFaqsEnglish', faqs.data.faqs);

            })
        },

        fetchFaqCategoryEnglish({ commit, getters }) {
            getters.getApolloClient.query({
                query: faqCategoryEnglishQuery
            }).then(faqCategory => {
                commit('setFaqCategoryEnglish', faqCategory.data.faqCategories)

            })
        },

        fetchTrafficFormsEnglish({ commit, getters, dispatch }) {
            getters.getApolloClient.query({
                query: trafficFormQuery
            }).then(form => {
                form.data.trafficForms.forEach(trafficForm => {
                    dispatch('saveInCache', trafficForm.formPdf[0].url).then(response => {
                        trafficForm.formPdf[0].url = response
                        commit('setTrafficFormsEnglish', trafficForm)
                    })
                })

            })
        },

        fetchEvictionFormsEnglish({ commit, getters, dispatch }) {
            getters.getApolloClient.query({
                query: evictionFormQuery
            }).then(form => {
                form.data.evictionForms.forEach(evictionForm => {
                    dispatch('saveInCache', evictionForm.formPdf[0].url).then(response => {
                        evictionForm.formPdf[0].url = response
                        commit('setEvictionFormsEnglish', evictionForm)
                    })
                })
            })
        },

        fetchPopupsEnglish({ commit, getters, dispatch }) {
            getters.getApolloClient.query({
                query: popupEnglishQuery
            }).then(popup => {
                popup.data.popups.forEach(pop => {
                    dispatch('saveInCache', pop.popupImage.url)
                        .then(response => {
                            commit('setPopupsEnglish', {
                                popupName: pop.popupName,
                                popupImage: response,
                            })
                        })
                })
            })
        }
    }
}
