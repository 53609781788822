import gql from 'graphql-tag'

// ---------------------------- Homepages --------------------------------------------

export const homepageEnglishQuery = gql`
query homeQuery {
  lobbyHomepages{
    id
    speech    
    menuName     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        type
        }
      }
    
    menuIcon {
      id
      url
    }
    displayPosition
  }
}
`

// ------------------------------ Faqs ----------------------------------------------

export const faqEnglishQuery = gql`
query faqQuery {
  faqs {
    id
    question
    answer
    category
  }
}
`
export const faqCategoryEnglishQuery = gql`
query faqCategoryQuery {
  faqCategories(sort: "displayOrder") {
    id
    categoryName
    displayType
    displayOrder
  }
}
`
// ------------------------------ Maps ----------------------------------------------
export const mapEnglishQuery = gql`
query mapQuery {
  lobbyMaps{
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorEnglishQuery = gql`
query floorQuery {
  lobbyFloors (sort: "floorNumber"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`
// --------------------------------------------- Forms --------------------------------------

export const trafficFormQuery = gql`
query trafficFormQuery {
	trafficForms{
    formName
    speech
    formPdf{
      id
      url
    }
  }
}
`


export const evictionFormQuery = gql`
query evictionFormQuery {
	evictionForms{
    formName
    speech
    formPdf{
      id
      url
    }
  }
}
`

// ------------------------------ Popups ----------------------------------------------

export const popupEnglishQuery = gql`
query popupQuery {
  popups{ 
    id
    popupName
    popupImage{
      id
      url
    }
  }
}
`
