<template>
  <v-row>
    <v-col cols="12" align="center">
      <v-card height="750" width="90%" class="overflow-auto pdfview" flat>
        <pdf :src="getPdfViewer.url"></pdf>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import pdf from "vue-pdf";
export default {
  name: "displayForms",
  components: {
    pdf,
  },
  computed: {
    ...mapGetters(["appDimensions", "getPdfViewer"]),
  },
};
</script>
<style>
.pdfview {
  border-width: 3px;
  border-style: solid;
  border-color: #3357c0 !important;
  border-radius: 5px !important;
}
</style>
